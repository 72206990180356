import { setTaskSolutionType } from './types';

const calculateMidPoint = (...args) =>
  args
    .reduce((acc = [], cur) => {
      if (!acc[0] && !acc[1]) {
        acc[0] = cur[0];
        acc[1] = cur[1];
      } else {
        acc[0] = acc[0] + cur[0];
        acc[1] = acc[1] + cur[1];
      }
      return acc;
    }, [])
    .map((el: number) => el / args.length);

const setTaskSolution: setTaskSolutionType = (state, action) => {
  const { nodes, pipelines, wells, ...props } = action.payload;
  const nodesById = action.payload?.nodes?.reduce((acc = {}, cur) => {
    // tslint:disable-next-line: no-parameter-reassignment
    acc = {
      ...acc,
      [cur.uid]: cur,
    };
    return acc;
  }, {});

  const wellsById = action.payload?.wells?.reduce((acc = {}, cur) => {
    if (!acc[cur.node_uid]) {
      // tslint:disable-next-line: no-parameter-reassignment
      acc = {
        ...acc,
        [cur.node_uid]: [],
      };
    }

    acc[cur.node_uid].push(cur);

    return acc;
  }, {});

  const pipelinesWithNodes = action.payload?.pipelines?.map(el => {
    const midCoords = calculateMidPoint(el.coords[0], el.coords[1]);
    return {
      ...el,
      midCoords,
      start_node_name: nodesById[el.start_node_uid]?.name,
      end_node_name: nodesById[el.end_node_uid]?.name,
    };
  });

  const wellsWithCoords = wells?.map(el => {
    const nodeId = el.node_uid;
    const { coords } = nodesById[nodeId];
    return {
      ...el,
      coords,
    };
  });

  state.taskSolution = {
    ...props,
    nodes,
    nodesById,
    wellsById,
    wells: wellsWithCoords,
    pipelines: pipelinesWithNodes,
  };
};

export default {
  setTaskSolution,
};
