import React, { createRef } from 'react';
import './style.scss';
import { handleStorage } from '../../services/handleStorage';
import { setAuth } from '../../Login/authSlice';
import { connect } from 'react-redux';

interface IUserLogoState {
  activePopupMenu: boolean;
}

interface IUserLogoPropsType {
  setAuth: typeof setAuth;
}

class UserLogo extends React.Component<IUserLogoPropsType> {
  containerNode = createRef<HTMLDivElement>();
  state: IUserLogoState = {
    activePopupMenu: false,
  };

  componentDidMount(): void {
    document.addEventListener('mousedown', this.onMouseDown, true);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.onMouseDown, true);
  }

  onMouseDown = (e: MouseEvent) => {
    if (this.containerNode && this.containerNode.current) {
      if (!this.containerNode.current.contains(e.target as Node)) {
        this.hidePopupMenu();
      }
    }
  };

  hidePopupMenu = () => {
    this.setState({ activePopupMenu: false });
  };

  togglePopupMenu = () => {
    this.setState({ activePopupMenu: !this.state.activePopupMenu });
  };

  logout = () => {
    this.hidePopupMenu();
    handleStorage.removeToken();
    this.props.setAuth(false);
  };

  render() {
    const { activePopupMenu } = this.state;
    return (
      <div className="user-logo-container" ref={this.containerNode}>
        <button className="user-logo" onClick={this.togglePopupMenu} />
        {activePopupMenu && (
          <div className="user-logo-popup-menu">
            <button onClick={this.logout}>Выход</button>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuth,
};

export default connect(null, mapDispatchToProps)(UserLogo);
