import React from 'react';
import classnames from 'classnames';
import { formatter } from '../../utils';
import { Skeleton } from '@material-ui/lab';
import { ITableRowProps } from './types';
import { handleTdIndex } from './utils';

const handleValue = (value: string | number, tdIndex: number) => {
  if (value !== undefined) {
    if (typeof value === 'string') return value;
    return formatter.format(value);
  }
  return (
    <Skeleton
      variant="rect"
      classes={{
        root: `skeleton-root ${handleTdIndex(tdIndex)}`,
      }}
    />
  );
};

const TableRow: React.FC<ITableRowProps> = ({
  el,
  active,
  headKeys,
  activeHover,
  onClickHandler,
  onMouseEnterHandler,
  onMouseLeaveHandler,
}) => {
  const onClick = () => onClickHandler(el);
  const onMouseEnter = () => onMouseEnterHandler(el);

  return (
    <tr
      onMouseLeave={onMouseLeaveHandler}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      className={classnames('table-row', {
        active,
        'active-hover': activeHover,
      })}
    >
      {headKeys.map((key: string, tdIndex: number) => {
        const value = handleValue(el[key], tdIndex);
        return (
          <td className="table-data" key={tdIndex}>
            {value}
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
