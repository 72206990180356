import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LeftCol from 'components/LeftCol';
import LeftColSubTopRow from 'components/LeftCol/LeftColSubTopRow';
import Button from 'components/Button';
import Tables from './Tables';
import Dashboard from './Dashboard';
import Comparation from './Comparation';
import { dashboardItems, comparationItems } from './utils';
import NetworkNotFound from './NetworkNotFound';

import { NetworkStateType, NetworkPropsType } from './types';
import { getSolution, calculateOptimizedTask, downloadResult } from './service';
import { setTaskSolution, selectTaskSolution } from './networkSlice';

import './style.scss';
import LoadingPanel from './LoadingPanel';
import Wrapper from '../Wrapper';
import ErrorPage from '../ErrorPage';
import { StoreType } from 'store';

class Network extends React.Component<NetworkPropsType> {
  state: NetworkStateType = {
    items: dashboardItems(),
    optimized: false,
    comparation: comparationItems(null),
    buttonTitle: 'Оптимизировать',
    loaded: false,
    notFoundError: false,
    internalServerError: false,
    restoreButtonMode: 'primary',
  };

  componentDidMount() {
    this.handleRoute();
  }

  componentDidUpdate(prevProps: NetworkPropsType) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setTaskSolution({});
      this.setState({
        loaded: false,
        items: dashboardItems(),
        notFoundError: false,
        internalServerError: false,
      });
      this.handleRoute();
    }
  }

  handleRoute() {
    const {
      match: { params },
    } = this.props;
    const { id } = params as { id?: string };

    if (id) this.getTaskSolution(id);
  }

  getTaskSolution = async (taskId: string) => {
    const {
      location: { pathname },
    } = this.props;
    const optimized = pathname.includes('optimized');
    try {
      const { data } = await getSolution({ taskId });

      this.props.setTaskSolution(data);

      const buttonTitle = optimized ? 'Скачать результаты' : 'Оптимизировать';

      this.setState({
        optimized,
        buttonTitle,
        items: dashboardItems(data, optimized),
        comparation: comparationItems(data),
        loaded: true,
      });
    } catch (err) {
      this.setState({ loaded: true });
      switch (err.response?.status) {
        case 404:
        case 422:
          this.setState({ notFoundError: true });
          break;
        case 500:
          this.setState({ internalServerError: true });
          break;
        default:
          break;
      }
    }
  };

  optimizeSolution = async () => {
    if (this.state.restoreButtonMode === 'execution') return;
    const {
      location: { pathname },
      match: { params },
      history,
      taskSolution,
    } = this.props;
    const { id } = params as { id?: string };
    const optimized = pathname.includes('optimized');
    if (!optimized) {
      const task = taskSolution?.task;
      if (task) {
        try {
          this.setState({
            restoreButtonMode: 'execution',
            buttonTitle: 'Оптимизация...',
          });

          const { data } = await calculateOptimizedTask({ id, task });

          const uid = data?.uid;
          if (uid) {
            history.push(`/optimized-network/${uid}`);
            this.setState({ restoreButtonMode: 'primary' });
          }
        } catch (err) {
          this.setState({ restoreButtonMode: 'primary' });
        }
      }
    } else if (id) downloadResult(id);
  };

  render() {
    const {
      buttonTitle,
      items,
      comparation,
      optimized,
      loaded,
      notFoundError,
      internalServerError,
      restoreButtonMode,
    } = this.state;

    if (internalServerError) return <ErrorPage type="internalServerError" />;

    return (
      <Wrapper>
        <LeftCol hideLink={notFoundError}>
          {notFoundError ? (
            <NetworkNotFound />
          ) : (
            <>
              <LeftColSubTopRow>
                <div className="network-top-row">
                  <h1>Восстановленная сеть</h1>
                  <Button
                    mode={restoreButtonMode}
                    onClick={this.optimizeSolution}
                  >
                    {buttonTitle}
                  </Button>
                </div>
              </LeftColSubTopRow>
              <div className="network-container">
                <Dashboard mode="big" dashboardItems={items} />
                <Comparation
                  optimized={optimized}
                  dashboardItems={comparation}
                />
                <Tables />
              </div>
            </>
          )}
        </LeftCol>
        {!loaded && <LoadingPanel />}
      </Wrapper>
    );
  }
}

const mapDispatchToProps = {
  setTaskSolution,
};

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Network));
