import React from 'react';
import './overviewPanel.scss';
import { ValueType } from 'react-select';
import { CenterType, OptionType, OverviewInfoType } from '../types';
import OverviewTable from './OverviewTable';
import classnames from 'classnames';
import ScrollableWrapper from '../ScrollableWrapper';

interface IOverviewPanelProps {
  index: number;
  wellfieldValue: ValueType<OptionType>;
  centers: CenterType[];
  overviewInfo: OverviewInfoType;
}

class OverviewPanel extends React.Component<IOverviewPanelProps> {
  scrollContainer = React.createRef<HTMLDivElement>();

  render () {
    return (
      <div className={classnames('overview-panel-container')}>
        <div className="overview-panel-header">
          <span className="overview-panel-header-text">Общие сведения</span>
        </div>
        {this.props.wellfieldValue ? (
          <ScrollableWrapper divRef={this.scrollContainer} topShadowClass="overview-panel-top-shadow">
            <div
              ref={this.scrollContainer}
              className="overview-wellfield-info-container relative"
            >
              <div className="wellfield-name-icon" />
              <span className="wellfield-name-text">
                {this.props.wellfieldValue
                  ? (this.props.wellfieldValue as OptionType).label
                  : ''}
              </span>
              {this.props.index === 1 || this.props.index === 2 ? (
                <>
                  <span className="wellfield-desc-text">
                    Из{' '}
                    <span className="wellfield-desc-text bold">
                      {this.props.overviewInfo.techmodeWellsCount}
                    </span>{' '}
                    скважин Холмогорского месторождения в статусе «в работе»
                    по данным Техрежима Таблицы скважин загружены для{' '}
                    <span className="wellfield-desc-text bold">
                      {this.props.overviewInfo.wellsTablesCount}
                    </span>{' '}
                    скважин.
                  </span>
                  <OverviewTable overviewInfo={this.props.overviewInfo} />
                </>
              ) : (
                <></>
              )}
              {this.props.index === 2 ? (
                <div className="overview-centers-container">
                  {this.props.centers.map((item, ix) => {
                    return (
                      <div key={ix} className="overview-center-row">
                        <div className="overview-center-icon" />
                        <span className="overview-center-row-name-text">
                          {item.name}
                        </span>
                        <div className="clearfix" />
                        <div className="overview-center-icon empty" />
                        <span className="overview-center-row-pressure-text">
                          {item.pressure} атм
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
            </div>
          </ScrollableWrapper>
        ) : (
          <div className="overview-initial-info">
            <span className="overview-initial-info-text">
              Тут отобразится информация о выбранном месторождении, <br />
              загруженных скважинах, выбранных центра сбора
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default OverviewPanel;
