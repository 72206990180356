import * as React from 'react';
import _throttle from 'lodash.throttle';
import classnames from 'classnames';

interface IScrollableWrapperProps {
  divRef: React.RefObject<HTMLDivElement>;
  topShadowClass?: string;
  bottomShadowClass?: string;
}

interface IScrollableWrapperState {
  scrolled: boolean;
}

class ScrollableWrapper extends React.Component<IScrollableWrapperProps> {
  state: IScrollableWrapperState = {
    scrolled: false,
  };

  componentDidMount(): void {
    this.props.divRef.current?.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.props.divRef.current?.removeEventListener(
      'scroll',
      this.handleScroll,
    );
  }

  handleScroll = () => _throttle(this.updateScroll, 300)();

  updateScroll = () => {
    const scrollTop = this.props.divRef.current?.scrollTop;
    if (this.state.scrolled !== !!scrollTop) {
      this.setState({ scrolled: !!scrollTop });
    }
  };

  render() {
    const { scrolled } = this.state;
    const { topShadowClass, bottomShadowClass } = this.props;
    return (
      <div className={classnames('scrollable-wrapper', { scrolled })}>
        <div className="top-shadow-wrapper relative">
          <div className={classnames('top-shadow', topShadowClass)} />
        </div>
        {this.props.children}
        <div className="bottom-shadow-wrapper relative">
          <div className={classnames('bottom-shadow', bottomShadowClass)}/>
        </div>
      </div>
    );
  }
}

export default ScrollableWrapper;
