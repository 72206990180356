import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { PrivateRouteProps } from './types';

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  auth,
  ...rest
}) => {
  const render = props =>
    !auth ? <Redirect to="/login" /> : <Component {...props} {...rest} />;
  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
