import React from 'react';

type ISetStyle = (style: React.CSSProperties) => void;

export function overflowOnResize(panelRef: React.RefObject<HTMLDivElement>, setPanelStyle: ISetStyle) {
  if (panelRef && panelRef.current) {
    const maxHeight = parseInt(
      window
        .getComputedStyle(panelRef.current)
        .getPropertyValue('max-height'),
      10,
    );
    const height = panelRef.current.scrollHeight;
    if (height >= maxHeight) {
      setPanelStyle({ overflow: 'auto' });
    } else {
      setPanelStyle({ overflow: 'unset' });
    }
  }
}
