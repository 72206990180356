import React, { createRef } from 'react';
import Dropdown from '../../components/Dropdown';
import Droparea from './Droparea';
import UploadedFile from './uploadedFile';
import { ValueType } from 'react-select';
import {
  DropzoneError,
  OptionType, UploadedFileError,
  UploadedFileType,
  WellfieldError,
  WellfieldErrorType,
} from '../types';
import classnames from 'classnames';
import { overflowOnResize } from '../overflowOnResize';
import ScrollableWrapper from '../ScrollableWrapper';
import ErrorsList from './ErrorsList';

interface IWellfieldSettingsProps {
  wellfieldValue?: ValueType<OptionType>;
  setWellfieldValue(value: ValueType<OptionType>): void;
  wellfieldValueError?: WellfieldError;
  setWellfieldValueError?(value: WellfieldError): void;
  uploadedFiles: UploadedFileType[];
  uploadedFilesErrors: UploadedFileError[];
  setUploadedFiles(files: UploadedFileType[]): void;
  dropzoneError: DropzoneError;
  setDropzoneError(error: DropzoneError): void;
  wellfieldOptions: OptionType[];
}

interface IWellfieldSettingsState {
  panelStyle: React.CSSProperties;
  scrolled: boolean;
  added: boolean;
}

class WellfieldSettings extends React.Component<IWellfieldSettingsProps> {
  panelRef = createRef<HTMLDivElement>();
  state: IWellfieldSettingsState = {
    panelStyle: { overflow: 'unset' },
    scrolled: false,
    added: false,
  };
  componentDidMount(): void {
    const ref = this.panelRef.current;
    window.addEventListener('resize', this.onResize);
    ref?.addEventListener('scroll', this.onScroll);
    this.onResize();
  }

  componentWillUnmount(): void {
    const ref = this.panelRef.current;
    window.removeEventListener('resize', this.onResize);
    ref?.removeEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(
    prevProps: IWellfieldSettingsProps,
    prevState: IWellfieldSettingsState,
  ): void {
    if (prevProps.uploadedFiles.length !== this.props.uploadedFiles.length) {
      this.onResize();
      this.onAdd();
    }
    if (prevProps.dropzoneError.error !== this.props.dropzoneError.error) {
      this.onResize();
    }
    if (
      prevProps.uploadedFilesErrors.length !==
      this.props.uploadedFilesErrors.length
    ) {
      this.onResize();
    }
  }

  onScroll = () => {
    this.setState({ scrolled: !this.state.scrolled });
  };

  onAdd = () => {
    this.setState({ added: !this.state.added });
  };

  onResize = () => {
    overflowOnResize(this.panelRef, this.setPanelStyle);
  };

  setPanelStyle = style => {
    this.setState({ panelStyle: style });
  };

  onFileUpload = (files: UploadedFileType[]) => {
    const savedFiles = [...this.props.uploadedFiles, ...files];
    this.props.setUploadedFiles(savedFiles);
    if (
      this.props.dropzoneError &&
      typeof this.props.setDropzoneError === 'function'
    ) {
      this.props.setDropzoneError({ error: false, errorsList: [] });
    }
  };
  onFileDelete = (index: number) => {
    const files = [...this.props.uploadedFiles];
    files.splice(index, 1);
    const isErrors = files.find(value => value.error.error);
    if (!isErrors) {
      this.props.setDropzoneError({ error: false, errorsList: [] });
    }
    this.props.setUploadedFiles(files);
  };
  onChangeWellfield = (e: ValueType<OptionType>) => {
    this.props.setWellfieldValue(e);
    if (
      this.props.wellfieldValueError?.error &&
      typeof this.props.setWellfieldValueError === 'function'
    ) {
      this.props.setWellfieldValueError({ error: false });
    }
  };
  getMessageByErrorType = (
    errorType: keyof typeof WellfieldErrorType | undefined,
  ) => {
    switch (errorType) {
      case 'notChosen':
        return 'Для расчета необходимо выбрать месторождение';
      case 'notFound':
        return 'На данном месторождении отсутствуют центры сбора';
      default:
        return '';
    }
  };
  render() {
    const { panelStyle, scrolled, added } = this.state;
    const {
      wellfieldOptions,
      wellfieldValue,
      wellfieldValueError,
      dropzoneError,
      uploadedFiles,
    } = this.props;
    return (
      <ScrollableWrapper
        divRef={this.panelRef}
        bottomShadowClass="settings-bottom-shadow"
      >
        <div
          className="settings-content-container wellfield-settings-container"
          ref={this.panelRef}
          style={panelStyle}
        >
          <span className="settings-subtitle">1. Выбор месторождения</span>
          <div className="settings-panel">
            <span className="settings-dropdown-label">Месторождение</span>
            <div className="settings-dropdown-container relative">
              <Dropdown
                options={wellfieldOptions}
                placeholder=""
                value={wellfieldValue}
                onChange={this.onChangeWellfield}
                error={wellfieldValueError?.error}
              />
              <div
                className={classnames(
                  'settings-tooltip',
                  wellfieldValueError?.error &&
                    wellfieldValueError?.errorType &&
                    'error',
                )}
              >
                <span className="settings-tooltip-text">
                  {this.getMessageByErrorType(wellfieldValueError?.errorType)}
                </span>
              </div>
            </div>
            <span className="settings-dropdown-label files-label">
              Файлы «ТехРежим.xls» и «Скважины.xlsx»
            </span>
            <div className="mode-table-wrapper relative">
              <Droparea
                onFileUpload={this.onFileUpload}
                uploadedFiles={uploadedFiles}
                error={dropzoneError}
              />
              {uploadedFiles?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <UploadedFile
                      fileName={item.name}
                      error={item.error}
                      index={index}
                      onFileDelete={this.onFileDelete}
                      panRef={this.panelRef}
                      scrolled={scrolled}
                      added={added}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <ErrorsList uploadedFiles={uploadedFiles} dropzoneError={dropzoneError} />
        </div>
      </ScrollableWrapper>
    );
  }
}

export default WellfieldSettings;
