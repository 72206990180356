import React from 'react';
import LeftCol from '../components/LeftCol';
import './settings.scss';
import SettingsFrame from './SettingsFrame';
import { ValueType } from 'react-select';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  OptionType,
  CenterType,
  ParameterType,
  UploadedFileType,
  OverviewInfoType,
  DropzoneError,
  WellfieldError,
} from './types';
import { formatter } from '../utils';
import OverviewPanel from './OverviewPanel/OverviewPanel';
import {
  getWellfields,
  getGatheringCenters,
  uploadFile,
  getOverviewInfo,
  restoreNetwork,
} from './service';
import LeftColSubTopRow from '../components/LeftCol/LeftColSubTopRow';
import ButtonsPanel from './ButtonsPanel';
import Wrapper from '../Wrapper';
import ErrorPage from '../ErrorPage';
import { selectTaskSolution } from 'Network/networkSlice';
import { StoreType } from '../store';
import { connect } from 'react-redux';

const initParameters = [
  {
    value: formatter.format(0.1).toString(),
    label: 'Шероховатость',
    labelAddition: null,
    error: false,
  },
  {
    value: '859',
    label: 'Плотность нефти, кг/м',
    labelAddition: <sup>3</sup>,
    error: false,
  },
  {
    value: '1000',
    label: 'Плотность воды, кг/м',
    labelAddition: <sup>3</sup>,
    error: false,
  },
  {
    value: formatter.format(0.7).toString(),
    label: 'Отн. плотность газа',
    labelAddition: null,
    error: false,
  },
  {
    value: '4',
    label: 'Вязкость нефти при 20',
    labelAddition: <>&deg;C</>,
    error: false,
  },
  {
    value: '2',
    label: 'Вязкость нефти при 50',
    labelAddition: <>&deg;C</>,
    error: false,
  },
  {
    value: '20',
    label: 'Температура смеси, ',
    labelAddition: <>&deg;C</>,
    error: false,
  },
];

type ISettingsProps = RouteComponentProps & {
  taskSolution: ReturnType<typeof selectTaskSolution>;
};

interface ISettingsState {
  frameIx: number;
  wellfieldValue: ValueType<OptionType>;
  wellfieldValueError: WellfieldError;
  uploadedFiles: UploadedFileType[];
  dropzoneError: DropzoneError;
  centerValue: ValueType<OptionType>;
  centerToAdd: CenterType;
  centers: CenterType[];
  centersOptions: OptionType[];
  parameters: ParameterType[];
  taskCheckbox1: boolean;
  taskCheckbox2: boolean;
  wellfieldOptions: OptionType[];
  initCentersOptions: OptionType[];
  overviewInfo: OverviewInfoType;
  wellfieldSettingsStepExecuting: boolean;
  taskSettingsStepExecuting: boolean;
  internalServerError: boolean;
}

class Settings extends React.Component<ISettingsProps> {
  state: ISettingsState = {
    frameIx: 0,
    wellfieldValue: null,
    wellfieldValueError: { error: false },
    uploadedFiles: [],
    dropzoneError: { error: false, errorsList: [] },
    centerValue: null,
    centerToAdd: { name: '', uid: '', pressure: undefined },
    centers: [],
    centersOptions: [],
    parameters: initParameters,
    taskCheckbox1: false,
    taskCheckbox2: false,
    wellfieldOptions: [],
    initCentersOptions: [],
    overviewInfo: {
      techmodeWellsCount: 0,
      wellsTablesCount: 0,
      dfAvg: 0,
      dfMax: 0,
      dpAvg: 0,
      dpMax: 0,
      dFluidFlowAvg: 0,
      dFluidFlowMax: 0,
      dGasFlowAvg: 0,
      dGasFlowMax: 0,
      dOilFlowAvg: 0,
      dOilFlowMax: 0,
    },
    wellfieldSettingsStepExecuting: false,
    taskSettingsStepExecuting: false,
    internalServerError: false,
  };
  async componentDidMount(): Promise<any> {
    try {
      const options = await getWellfields();
      const wellfieldOptions: OptionType[] = [];
      if (options.data) {
        options.data.forEach(record => {
          wellfieldOptions.push({ value: record.uid, label: record.name });
        });
      }
      this.setState({ wellfieldOptions }, this.restoreSettings);
    } catch (err) {
      switch (err.response?.status) {
        case 404:
          return;
        case 500:
          this.setState({ internalServerError: true });
          return;
        default:
          return;
      }
    }
  }

  restoreSettings = async () => {
    if (this.props.taskSolution) {
      this.restoreWellfield();
      this.restoreFiles();
      this.restoreTaskSettings();
    }
  };

  restoreWellfield = async () => {
    const { wellfieldOptions } = this.state;
    const wellfield = wellfieldOptions.find(
      item => item.value === this.props.taskSolution.task?.wellfield_uid,
    );
    if (wellfield) {
      this.setState({ wellfieldValue: wellfield });
      await this.gatheringCentersRequest(
        wellfield,
        this.restoreGatheringCenters,
      );
    }
  };

  restoreFiles = () => {
    const files: UploadedFileType[] = [
      { uid: '', name: '', file: null, error: { error: false } },
      { uid: '', name: '', file: null, error: { error: false } },
    ];
    for (const [index, file] of this.props.taskSolution.task?.files.entries()) {
      files[index].uid = file.uid;
      files[index].name = file.name;
      if (file.name.split('.')[0] === 'ТехРежим') {
        this.setState({ techmodeFileUid: file.uid });
      } else if (file.name.split('.')[0] === 'Скважины') {
        this.setState({ wellsTablesFileUid: file.uid });
      }
    }
    this.setState({ uploadedFiles: files });
  };

  restoreGatheringCenters = () => {
    const { centersOptions } = this.state;
    const centers = Object.entries(this.props.taskSolution.task?.gc_pressures);
    const restoredCenters: CenterType[] = [];
    for (const [center, value] of centers) {
      const foundCenter: OptionType | undefined = centersOptions.find(
        item => item.value === center,
      );
      if (foundCenter) {
        restoredCenters.push({
          pressure: (value as number).toString(),
          name: foundCenter.label,
          uid: foundCenter.value,
        });
        const currentOptions = [...centersOptions];
        const ix = currentOptions.findIndex(obj => {
          return (obj as OptionType).value === foundCenter.value;
        });
        if (ix > -1) {
          currentOptions.splice(ix, 1);
        }
        this.setCentersOptions(currentOptions);
      }
    }
    this.setState({ centers: restoredCenters });
  };

  restoreTaskSettings = async () => {
    const { taskSolution } = this.props;
    const { parameters } = this.state;
    const restoredParameters = [...parameters];
    restoredParameters[0].value = formatter.format(
      taskSolution.task?.phys_chem?.roughness_mm.toString(),
    );
    restoredParameters[1].value = formatter.format(
      taskSolution.task?.phys_chem?.oil_density_kg_m3.toString(),
    );
    restoredParameters[2].value = formatter.format(
      taskSolution.task?.phys_chem?.water_density_kg_m3.toString(),
    );
    restoredParameters[3].value = formatter.format(
      taskSolution.task?.phys_chem?.gas_rel_density.toString(),
    );
    restoredParameters[4].value = formatter.format(
      taskSolution.task?.phys_chem?.oil_viscosity_20_cp.toString(),
    );
    restoredParameters[5].value = formatter.format(
      taskSolution.task?.phys_chem?.oil_viscosity_50_cp.toString(),
    );
    restoredParameters[6].value = formatter.format(
      taskSolution.task?.phys_chem?.fluid_temperature.toString(),
    );
    this.setState({
      parameters: restoredParameters,
      taskCheckbox1: taskSolution.task?.use_aspo,
      taskCheckbox2: taskSolution.task?.use_inclination,
    });
  };

  onNextBtnClick = async () => {
    const { frameIx, wellfieldValue, uploadedFiles } = this.state;
    const techModeFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'ТехРежим',
    );
    const wellsFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'Скважины',
    );
    switch (frameIx) {
      case 0: {
        if (!wellfieldValue) {
          this.setWellfieldValueError({ error: true, errorType: 'notChosen' });
        }
        if (!techModeFile && !wellsFile) {
          this.setDropzoneError({ error: true, errorsList: ['noTechModeFile', 'noWellsFile'] });
        } else if (!techModeFile) {
          this.setDropzoneError({ error: true, errorsList: ['noTechModeFile'] });
        } else if (!wellsFile) {
          this.setDropzoneError({ error: true, errorsList: ['noWellsFile'] });
        } else if (wellfieldValue) {
          this.applyWellfieldSettings();
        }
        return;
      }
      case 1: {
        let errors = false;
        if (this.state.centers.length < 1) {
          const center = { ...this.state.centerToAdd };
          center.nameError = true;
          center.nameErrorType = 'noCentersAdded';
          this.setCenterToAdd(center);
          errors = true;
        }
        this.state.centers.forEach(item => {
          if (item.pressureError) errors = true;
        });
        if (!errors) this.setFrameIx(this.state.frameIx + 1);
        return;
      }
      default:
        return;
    }
  };

  uploadFiles = async () => {
    let errors = false;
    const { uploadedFiles, wellfieldValue } = this.state;
    const changedFiles = [...uploadedFiles];
    for (const [index, file] of uploadedFiles.entries()) {
      if (file.uid === '') {
        try {
          const formData = new FormData();
          formData.append(
            'wellfield_uid',
            (wellfieldValue as OptionType).value,
          );
          if (file.file) {
            formData.append('file', file.file);
          }
          const response = await uploadFile(formData);
          changedFiles[index].uid = response?.data?.uid;
          changedFiles[index].name = response?.data?.name;
        } catch (err) {
          errors = true;
          switch (err.response?.status) {
            case 400:
              changedFiles[index].error.error = true;
              if (err.response?.data?.user_message === 'Некорректное имя файла.') {
                changedFiles[index].error.errorType = 'wrongName';
              } else {
                changedFiles[index].error.errorType = 'wrongFile';
              }
              break;
            case 500:
              this.setState({ internalServerError: true });
              break;
            default:
          }
        }
      }
    }
    if (errors) {
      this.setDropzoneError({ error: true, errorsList: ['fileError'] });
      throw new Error();
    }
    this.setState({ uploadedFiles: changedFiles });
  };

  applyWellfieldSettings = async () => {
    let isErrors = false;
    this.setState({ wellfieldSettingsStepExecuting: true });
    try {
      await this.uploadFiles();
      await this.overviewInfoRequest();
    } catch {
      isErrors = true;
    }
    if (!isErrors) this.setFrameIx(this.state.frameIx + 1);
    this.setState({ wellfieldSettingsStepExecuting: false });
  };

  overviewInfoRequest = async () => {
    const { uploadedFiles } = this.state;
    const techModeFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'ТехРежим',
    );
    const wellsFile = uploadedFiles.find(
      item => item.name.split('.')[0] === 'Скважины',
    );
    if (wellsFile && techModeFile) {
      try {
        const info: OverviewInfoType = { ...this.state.overviewInfo };
        const response = await getOverviewInfo(techModeFile.uid, wellsFile.uid);
        if (response.data) {
          info.techmodeWellsCount = response.data.techmode_wells_count;
          info.wellsTablesCount = response.data.wells_tables_count;
          info.dpAvg = response.data.dp_avg;
          info.dpMax = response.data.dp_max;
          info.dfAvg = response.data.df_avg;
          info.dfMax = response.data.df_max;
          info.dFluidFlowAvg = response.data.d_fluid_flow_avg;
          info.dFluidFlowMax = response.data.d_fluid_flow_max;
          info.dOilFlowAvg = response.data.d_oil_flow_avg;
          info.dOilFlowMax = response.data.d_oil_flow_max;
          info.dGasFlowAvg = response.data.d_gas_flow_avg;
          info.dGasFlowMax = response.data.d_gas_flow_max;
          this.setState({ overviewInfo: info });
        }
      } catch (err) {
        switch (err.response?.status) {
          case 400:
            const changedFiles = [...uploadedFiles];
            for (const [index, file] of changedFiles.entries()) {
              if (
                file.name.split('.')[0] === 'ТехРежим' ||
                file.name.split('.')[0] === 'Скважины'
              ) {
                changedFiles[index].error.error = true;
                changedFiles[index].error.errorType = 'notCorrespondingFile';
              }
            }
            break;
          case 500:
            this.setState({ internalServerError: true });
            break;
          default:
            break;
        }
        throw new Error();
      }
    }
  };

  onRestoreClick = async () => {
    const {
      centers,
      parameters,
      uploadedFiles,
      wellfieldValue,
      taskCheckbox1,
      taskCheckbox2,
    } = this.state;
    let isErrors = false;
    this.state.parameters.forEach(item => {
      if (item.error) {
        isErrors = true;
      }
    });
    if (!isErrors) {
      this.setState({ taskSettingsStepExecuting: true });
      try {
        const response = await restoreNetwork({
          uploadedFiles,
          centers,
          parameters,
          wellfieldUid: (wellfieldValue as OptionType).value,
          useAspo: taskCheckbox1,
          useInclination: taskCheckbox2,
        });
        if (response.data.uid) {
          this.setState({ taskSettingsStepExecuting: false });
          this.props.history.replace(`/network/${response.data.uid}`);
        }
      } catch (err) {
        switch (err.response?.status) {
          case 404:
            return;
          case 500:
            this.setState({ internalServerError: true });
            return;
          default:
            return;
        }
      }
    }
  };
  onPrevBtnClick = () => {
    this.setFrameIx(this.state.frameIx - 1);
  };
  setFrameIx = (ix: number) => {
    this.setState({ frameIx: ix });
  };
  setWellfieldValue = async (wellfieldValue: ValueType<OptionType>) => {
    this.gatheringCentersRequest(wellfieldValue, null);
    this.setState({ wellfieldValue });
  };
  gatheringCentersRequest = async (
    wellfieldValue: ValueType<OptionType>,
    restoreCenters,
  ) => {
    try {
      const options = await getGatheringCenters({
        wellfieldUid: (wellfieldValue as OptionType).value,
      });
      const initCentersOptions: OptionType[] = [];
      if (options.data) {
        for (const option of options.data) {
          initCentersOptions.push({ value: option.uid, label: option.name });
        }
        this.setState(
          {
            initCentersOptions,
            centersOptions: initCentersOptions,
            centers: [],
          },
          restoreCenters,
        );
      }
    } catch (err) {
      switch (err.response?.status) {
        case 404:
        case 422:
          this.setWellfieldValueError({ error: true, errorType: 'notFound' });
          return;
        case 500:
          this.setState({ internalServerError: true });
          return;
        default:
          this.setWellfieldValueError({ error: true });
          return;
      }
    }
  };
  setWellfieldValueError = (val: WellfieldError) => {
    this.setState({ wellfieldValueError: val });
  };
  setUploadedFiles = (val: UploadedFileType[]) => {
    this.setState({ uploadedFiles: val });
  };
  setDropzoneError = (error: DropzoneError) => {
    this.setState({ dropzoneError: error });
  };
  setCenterToAdd = (val: CenterType) => {
    this.setState({ centerToAdd: val });
  };
  setCenterValue = (val: ValueType<OptionType>) => {
    this.setState({ centerValue: val });
  };
  setCenters = (val: CenterType[]) => {
    this.setState({ centers: val });
  };
  setCentersOptions = (val: ValueType<OptionType>[]) => {
    this.setState({ centersOptions: val });
  };
  setParameters = (val: ParameterType[]) => {
    this.setState({ parameters: val });
  };
  setTaskCheckbox1 = (val: boolean) => {
    this.setState({ taskCheckbox1: val });
  };
  setTaskCheckbox2 = (val: boolean) => {
    this.setState({ taskCheckbox2: val });
  };
  render() {
    const {
      frameIx,
      wellfieldValue,
      wellfieldSettingsStepExecuting,
      taskSettingsStepExecuting,
      wellfieldOptions,
      wellfieldValueError,
      uploadedFiles,
      dropzoneError,
      centersOptions,
      centerToAdd,
      initCentersOptions,
      centerValue,
      centers,
      parameters,
      taskCheckbox1,
      taskCheckbox2,
      overviewInfo,
      internalServerError,
    } = this.state;
    return (
      <>
        {internalServerError ? (
          <ErrorPage type="internalServerError" />
        ) : (
          <Wrapper>
            <LeftCol>
              <LeftColSubTopRow>
                <div className="settings-top-row">
                  <span className="settings-title">Задание входных данных</span>
                </div>
              </LeftColSubTopRow>
              <div className="settings-container">
                <SettingsFrame
                  index={frameIx}
                  wellfieldValue={wellfieldValue}
                  setWellfieldValue={this.setWellfieldValue}
                  wellfieldValueError={wellfieldValueError}
                  setWellfieldValueError={this.setWellfieldValueError}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={this.setUploadedFiles}
                  dropzoneError={dropzoneError}
                  setDropzoneError={this.setDropzoneError}
                  centerToAdd={centerToAdd}
                  setCenterToAdd={this.setCenterToAdd}
                  centerValue={centerValue as OptionType}
                  setCenterValue={this.setCenterValue}
                  centers={centers}
                  setCenters={this.setCenters}
                  initOptions={initCentersOptions}
                  centersOptions={centersOptions}
                  setCentersOptions={this.setCentersOptions}
                  parameters={parameters}
                  setParameters={this.setParameters}
                  taskCheckbox1={taskCheckbox1}
                  setTaskCheckbox1={this.setTaskCheckbox1}
                  taskCheckbox2={taskCheckbox2}
                  setTaskCheckbox2={this.setTaskCheckbox2}
                  wellfieldOptions={wellfieldOptions}
                />
                <ButtonsPanel
                  frameIx={frameIx}
                  wellfieldSettingsStepExecuting={
                    wellfieldSettingsStepExecuting
                  }
                  taskSettingsStepExecuting={taskSettingsStepExecuting}
                  onPrevBtnClick={this.onPrevBtnClick}
                  onNextBtnClick={this.onNextBtnClick}
                  onRestoreClick={this.onRestoreClick}
                />
              </div>
            </LeftCol>
            <OverviewPanel
              index={frameIx}
              wellfieldValue={wellfieldValue}
              centers={centers}
              overviewInfo={overviewInfo}
            />
          </Wrapper>
        )}
      </>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
});

export default connect(mapStateToProps)(withRouter(Settings));
