import React, { useState } from 'react';
import Input from '../../components/Input';
import './gatheringCenterSettings.scss';
import { CenterType, OptionType } from '../types';
import classnames from 'classnames';
import { formatterForInt } from '../../utils';
import stringFormatter from '../../utils/stringFormatter';
import Dropdown from '../../components/Dropdown';
import { ValueType } from 'react-select';

interface ISelectCenterRowProps {
  centers: CenterType[];
  setCenters(centers: CenterType[]): void;
  centersOptions: ValueType<OptionType>[];
  setCentersOptions(options: ValueType<OptionType>[]);
  centerValue: ValueType<OptionType>;
  setCenterValue(value: ValueType<OptionType>): void;
  centerToAdd: CenterType;
  setCenterToAdd(value: CenterType);
  forceRerender?: boolean;
  setForceRerender?(value: boolean);
}

// tslint:disable-next-line:max-func-body-length
const SelectGatheringCenterRow: React.FC<ISelectCenterRowProps> = ({
  centers,
  setCenters,
  centersOptions,
  setCentersOptions,
  centerValue,
  setCenterValue,
  centerToAdd,
  setCenterToAdd,
}) => {
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const pressureInputMode = centerToAdd.pressureError ? 'error' : 'normal';
  const pressureInputValue = centerToAdd.pressure ? centerToAdd.pressure : '';
  const onChangePressure = (e: React.ChangeEvent<HTMLInputElement>) => {
    const spaces =
      stringFormatter(e.target.value).length - e.target.value.length;
    if (spaces > 0) {
      if (e.target.selectionStart) {
        setCursorPosition(e.target.selectionStart + spaces);
      }
    } else setCursorPosition(e.target.selectionStart);
    const center: CenterType = { ...centerToAdd };
    center.pressure = e.target.value;
    center.pressureError = false;
    setCenterToAdd(center);
  };
  const onChangeCenterName = (e: ValueType<OptionType>) => {
    setCenterValue(e);
    const center: CenterType = { ...centerToAdd };
    center.name = (e as OptionType).label;
    center.nameError = false;
    setCenterToAdd(center);
  };
  const onBlurHandler = () => {
    const center: CenterType = { ...centerToAdd };
    if (centerToAdd.pressure) {
      const commaToDotValue = centerToAdd.pressure.replace(',', '.');
      const valueWithoutSpaces = commaToDotValue.replace(/\s/g, '');
      const newValue = parseFloat(valueWithoutSpaces);
      if (newValue) {
        center.pressure = formatterForInt.format(newValue).toString();
      } else {
        center.pressure = centerToAdd.pressure;
        center.pressureError = true;
        center.pressureErrorType = 'notNumber';
      }
    } else {
      center.pressureError = true;
      center.pressureErrorType = 'emptyValue';
    }
    setCenterToAdd(center);
  };
  const addCenter = () => {
    const center: CenterType = { ...centerToAdd };
    if (
      (centerValue as OptionType)?.label &&
      centerToAdd.pressure &&
      !centerToAdd.pressureError
    ) {
      const newCenter: CenterType = {
        pressure: centerToAdd.pressure,
        name: (centerValue as OptionType).label,
        uid: (centerValue as OptionType).value,
      };
      setCenters([newCenter, ...centers]);
      const currentOptions = [...centersOptions];
      const ix = currentOptions.findIndex(obj => {
        return (obj as OptionType).label === (centerValue as OptionType).label;
      });
      if (ix > -1) {
        currentOptions.splice(ix, 1);
      }
      setCentersOptions(currentOptions);
      setCenterValue(null);
      center.name = '';
      center.uid = '';
      center.pressure = undefined;
      center.nameError = false;
    } else if (!(centerValue as OptionType)?.label) {
      center.nameError = true;
      center.nameErrorType = 'notSelected';
    } else if (!centerToAdd.pressure) {
      center.pressureError = true;
      center.pressureErrorType = 'emptyValue';
    }
    setCenterToAdd(center);
  };
  return (
    <div className="gathering-center-row select-row">
      <div className="gathering-center-column left">
        <span className="settings-dropdown-label">Центр сбора</span>
        <div className="settings-dropdown-container relative">
          <Dropdown
            options={centersOptions as OptionType[]}
            placeholder=""
            value={centerValue}
            onChange={onChangeCenterName}
            error={centerToAdd.nameError}
          />
          <div
            className={classnames(
              'settings-tooltip',
              centerToAdd.nameError ? 'error' : '',
            )}
          >
            <span className="settings-tooltip-text">
              {centerToAdd.nameErrorType === 'noCentersAdded'
                ? 'Для расчета необходим хотя бы 1 ЦС'
                : 'Выберите ЦС, чтобы добавить его в расчет'}
            </span>
          </div>
        </div>
      </div>
      <div className="dash-column left">
        <span className="dash-column-text">&mdash;</span>
      </div>
      <div className="pressure-column left">
        <span className="settings-dropdown-label">Давление, атм</span>
        <div className="settings-input-container relative">
          <Input
            value={stringFormatter(pressureInputValue)}
            onChange={onChangePressure}
            mode={pressureInputMode}
            onBlur={onBlurHandler}
            cursorPosition={cursorPosition}
          />
          <div
            className={classnames(
              'settings-tooltip gathering-center-pressure-tooltip',
              centerToAdd.pressureErrorType === 'emptyValue' ? 'empty-value' : 'not-number',
              centerToAdd.pressureError ? 'error' : '',
            )}
          >
            <span className="settings-tooltip-text">
              {centerToAdd.pressureErrorType === 'emptyValue'
                ? 'Укажите давление на ЦС'
                : 'Введите числовое значение'}
            </span>
          </div>
        </div>
      </div>
      <button className="gathering-center-row-btn add-btn" onClick={addCenter}>
        Добавить
      </button>
    </div>
  );
};

export default SelectGatheringCenterRow;
