import * as React from 'react';
import { IndicatorProps } from 'react-select';
import { ReactComponent as DropdownArrow } from '../../assets/images/dropdownArrow.svg';
import classnames from 'classnames';

const DropdownIndicator: React.FC<IndicatorProps<any>> = ({ selectProps }) => {
  const { menuIsOpen, isDisabled } = selectProps;
  return (
    <div
      className={classnames('fields-dropdown-arrow', {
        menuIsOpen,
        isDisabled,
      })}
    >
      <DropdownArrow />
    </div>
  );
};

export default DropdownIndicator;
