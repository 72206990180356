import React, { useState } from 'react';
import './overviewPanel.scss';
import classnames from 'classnames';
import { OverviewInfoType } from '../types';
import { formatter } from '../../utils';

interface IOverviewTableProps {
  overviewInfo: OverviewInfoType;
}

const OverviewTable: React.FC<IOverviewTableProps> = ({ overviewInfo }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const onClickHandler = () => {
    setExpanded(!expanded);
  };
  return (
    <div className="overview-wellfield-table-panel">
      <button className="table-caption-button" onClick={onClickHandler}>
        <span className="table-panel-caption-text">
          Валидация Таблиц скважин
        </span>
        <div
          className={classnames('table-panel-caption-arrow', { expanded })}
        />
      </button>
      <div className="clearfix" />
      {expanded && (
        <div>
          <span className="overview-wellfield-table-subtitle">
            Средняя/максимальная относительная погрешность
          </span>
          <table className="overview-wellfield-table">
            <tbody>
              <tr>
                <td className="overview-wellfield-table-parameter-cell">
                  δF, %
                </td>
                <td className="overview-wellfield-table-value-cell">
                  {formatter.format(overviewInfo.dfAvg)} /{' '}
                  {formatter.format(overviewInfo.dfMax)}
                </td>
                <td className="overview-wellfield-table-parameter-cell">
                  δQж, %
                </td>
                <td className="overview-wellfield-table-value-cell">
                  {formatter.format(overviewInfo.dFluidFlowAvg)} /{' '}
                  {formatter.format(overviewInfo.dFluidFlowMax)}
                </td>
              </tr>
              <tr>
                <td className="overview-wellfield-table-parameter-cell">
                  δP, %
                </td>
                <td className="overview-wellfield-table-value-cell">
                  {formatter.format(overviewInfo.dpAvg)} /{' '}
                  {formatter.format(overviewInfo.dpMax)}
                </td>
                <td className="overview-wellfield-table-parameter-cell">
                  δQн, %
                </td>
                <td className="overview-wellfield-table-value-cell">
                  {formatter.format(overviewInfo.dOilFlowAvg)} /{' '}
                  {formatter.format(overviewInfo.dOilFlowMax)}
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td className="overview-wellfield-table-parameter-cell">
                  δQг, %
                </td>
                <td className="overview-wellfield-table-value-cell">
                  {formatter.format(overviewInfo.dGasFlowAvg)} /{' '}
                  {formatter.format(overviewInfo.dGasFlowMax)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OverviewTable;
