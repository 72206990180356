import * as React from 'react';
import { formatter } from 'utils';
import { ITopRowRightColProps } from './types';

const TopRowRightCol: React.FC<ITopRowRightColProps> = ({ theta, aspo }) => {
  return (
    <td>
      <div className="popup-row__sub-row">
        <p className="popup-val-dem">АСПО, мм</p>
        <p className="popup-title">{formatter.format(aspo)}</p>
      </div>
      <div className="popup-row__sub-row">
        <p className="popup-val-dem">Угол наклона, рад</p>
        <p className="popup-title">{formatter.format(theta)}</p>
      </div>
    </td>
  );
};

export default TopRowRightCol;
