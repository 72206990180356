import axios, { AxiosRequestConfig } from 'axios';
import { handleStorage } from './services/handleStorage';
import updateAuthToken from './utils/updateAuthToken';

export const api = axios.create();

const handleRequest = (request: AxiosRequestConfig) => {
  if (!request.headers?.common?.Authorization) {
    const token = handleStorage.getToken();
    if (!token) {
      return request;
    }
    updateAuthToken(token);
    request.headers.common.Authorization = `Bearer ${token}`;
  }
  return request;
};

const handleError = error => {
  if (error.response.status === 401) {
    handleStorage.removeToken();
    if (!window.location.href.includes('login')) {
      window.location.replace('#/login');
    }
  }
  return Promise.reject(error);
};

api.interceptors.request.use(handleRequest);

api.interceptors.response.use(res => res, handleError);
