import React from 'react';
import './taskSettings.scss';
import TaskSettingsInput from './TaskSettingsInput';
import TaskSettingsCheckbox from './TaskSettingsCheckbox';
import { ParameterType } from '../types';

interface ITaskSettingsProps {
  parameters: ParameterType[];
  setParameters(value: ParameterType[]);
  taskCheckbox1: boolean;
  setTaskCheckbox1(value: boolean): void;
  taskCheckbox2: boolean;
  setTaskCheckbox2(value: boolean): void;
}

const TaskSettings: React.FC<ITaskSettingsProps> = ({
  parameters,
  setParameters,
  taskCheckbox1,
  setTaskCheckbox1,
  taskCheckbox2,
  setTaskCheckbox2,
}) => {
  return (
    <div className="settings-content-container task-settings-container">
      <span className="settings-subtitle">3. Настройки расчета</span>
      <div className="settings-panel">
        <div className="task-settings-row">
          {parameters.map((item, index) => {
            if (index < 4) {
              return (
                <React.Fragment key={index}>
                  <TaskSettingsInput
                    index={index}
                    item={item}
                    parameters={parameters}
                    setParameters={setParameters}
                  />
                </React.Fragment>
              );
            } return <React.Fragment key={index} />;
          })}
        </div>
        <div className="clearfix" />
        <div className="task-settings-row">
          {parameters.map((item, index) => {
            if (index > 3) {
              return (
                <React.Fragment key={index}>
                  <TaskSettingsInput
                    index={index}
                    item={item}
                    parameters={parameters}
                    setParameters={setParameters}
                  />
                </React.Fragment>
              );
            } return <React.Fragment key={index} />;
          })}
          <div className="clearfix" />
        </div>
        <div className="clearfix" />
        <div className="task-settings-row checkbox-row ">
          <TaskSettingsCheckbox
            checkedValue={taskCheckbox1}
            setCheckedValue={setTaskCheckbox1}
            label={'использовать АСПО при калибровке модели'}
            id={'task-settings-first-checkbox'}
          />
        </div>
        <div className="task-settings-row checkbox-row">
          <TaskSettingsCheckbox
            checkedValue={taskCheckbox2}
            setCheckedValue={setTaskCheckbox2}
            label={
              'использовать углы наклона трубопроводов при калибровке модели'
            }
            id={'task-settings-second-checkbox'}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskSettings;
