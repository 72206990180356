// tslint:disable-next-line: no-single-line-block-comment
/* eslint-disable react/style-prop-object */
import * as React from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import { connect } from 'react-redux';

import CustomZoomControl from './CustomZoomControl';
import PopupContainer from './PopupContainer';
import PipesLayer from './PipesLayer';
import WellfieldsLayer from './WellfieldsLayer';
import NodesLayer from './NodesLayer';
import { selectTaskSolution } from '../Network/networkSlice';

import './style.scss';

import { handlePopupType, IMapState, IMapProps } from './types';
import { StoreType } from 'store';
import { setPopupData } from './mapSlice';

const MapContainer = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoidW1pYm96dXJvIiwiYSI6ImNpdmh5MzRkZTAwNm8ydG55bHJycHVuNzEifQ.VYG8irr9z7lyzJGGoZQf0A',
});

const containerStyle: React.CSSProperties = {
  height: '100vh',
  width: '100%',
  float: 'right',
};

class Map extends React.Component<IMapProps> {
  mapRef = React.createRef<any>();

  state: IMapState = {
    popup: 'pipe',
    showPopup: false,
    mapZoom: [11],
    mapCenter: [74.38369000426658, 62.994261416456375],
    popupCoords: [30.26942, 59.941294],
  };

  componentDidUpdate(prevProp: IMapProps) {
    const { taskSolution } = this.props;
    if (taskSolution) {
      const lastUid = prevProp.taskSolution?.nodes?.[0]?.uid;
      const currentUid = taskSolution?.nodes?.[0]?.uid;
      if (lastUid !== currentUid && currentUid) {
        const { coords: mapCenter } = taskSolution.nodes[0];
        this.setState({ mapCenter });
      }
    }
  }

  handlePopup = ({ activeValue, popup }: handlePopupType) => {
    this.props.setPopupData({
      popupType: popup,
      ...activeValue,
    });
  };

  hidePopup = () => this.setState({ showPopup: false });

  render() {
    const { mapCenter, mapZoom } = this.state;
    return (
      <MapContainer
        ref={this.mapRef}
        style="mapbox://styles/umibozuro/cka0yxnql0av31irret0jtq4n"
        containerStyle={containerStyle}
        zoom={mapZoom}
        center={mapCenter}
      >
        <PipesLayer handlePopup={this.handlePopup} />
        <WellfieldsLayer handlePopup={this.handlePopup} />
        <NodesLayer handlePopup={this.handlePopup} />
        <CustomZoomControl mapRef={this.mapRef} />
        <PopupContainer />
      </MapContainer>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
});

const mapDispatchToProps = { setPopupData };

export default connect(mapStateToProps, mapDispatchToProps)(Map);
