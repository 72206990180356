import React from 'react';
import Button from '../components/Button';
import { ReactComponent as DotIcon } from '../assets/images/settings/dot.svg';
import classnames from 'classnames';

interface IButtonsPanelProps {
  frameIx: number;
  wellfieldSettingsStepExecuting: boolean;
  taskSettingsStepExecuting: boolean;
  onPrevBtnClick(): void;
  onNextBtnClick(): void;
  onRestoreClick(): void;
}

const ButtonsPanel: React.FC<IButtonsPanelProps> = ({
  frameIx,
  wellfieldSettingsStepExecuting,
  taskSettingsStepExecuting,
  onPrevBtnClick,
  onNextBtnClick,
  onRestoreClick,
}) => {
  const buttonMode = wellfieldSettingsStepExecuting ? 'execution' : 'primary';
  const restoreButtonMode = taskSettingsStepExecuting ? 'execution' : 'primary';
  return (
    <div className="settings-container-button-panel">
      <div className="button-panel-wrapper">
        {frameIx > 0 && (
          <Button
            className="settings-container-prev-btn"
            mode="secondary"
            onClick={onPrevBtnClick}
          >
            Назад
          </Button>
        )}
        <div className="settings-container-dot-icons-wrapper">
          <DotIcon
            className={classnames('settings-container-dot-icon', 'highlighted')}
          />
          <DotIcon
            className={classnames(
              'settings-container-dot-icon',
              frameIx > 0 ? 'highlighted' : '',
            )}
          />
          <DotIcon
            className={classnames(
              'settings-container-dot-icon',
              frameIx > 1 ? 'highlighted' : '',
            )}
          />
        </div>
        {frameIx < 2 && (
          <Button
            className="settings-container-next-btn"
            mode={buttonMode}
            onClick={onNextBtnClick}
          >
            {wellfieldSettingsStepExecuting
              ? 'Загрузка...'
              : 'Дальше'}
          </Button>
        )}
        {frameIx === 2 && (
          <Button
            className="settings-container-next-btn"
            mode={restoreButtonMode}
            onClick={onRestoreClick}
          >
            Восстановить
          </Button>
        )}
      </div>
    </div>
  );
};
export default ButtonsPanel;
