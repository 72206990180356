import React, { useRef } from 'react';
import './wellfieldSettings.scss';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import classnames from 'classnames';
import { DropzoneError, UploadedFileType } from '../types';

interface IDropareaProps {
  onFileUpload(files: UploadedFileType[]): void;
  uploadedFiles: UploadedFileType[];
  error: DropzoneError;
}

const Droparea: React.FC<IDropareaProps> = ({
  onFileUpload,
  uploadedFiles,
  error,
}) => {
  const dropZoneNode = useRef<DropzoneRef | null>(null);
  const onUploadInputChange = e => {
    saveFiles(e.target.files);
  };
  const onDropFile = files => {
    saveFiles(files);
  };
  const saveFiles = (files: File[]) => {
    let savedFiles: UploadedFileType[] = [];
    files.forEach(value => {
      if (
        value &&
        (value.type.match(
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ) ||
          value.name.split('.').pop() === 'xls' ||
          value.name.split('.').pop() === 'csv')
      ) {
        savedFiles = [
          ...savedFiles,
          { uid: '', file: value, name: value.name, error: { error: false } },
        ];
      }
    });
    onFileUpload(savedFiles);
  };

  return (
    <Dropzone onDrop={onDropFile} ref={dropZoneNode}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          className={classnames(
            'wellfield-settings-drop-area relative',
            isDragActive ? 'dragging' : '',
            uploadedFiles.length > 0 ? 'small-size' : '',
            error.error && 'error',
          )}
          {...getRootProps()}
        >
          <div className="drop-area-button-wrapper">
            {isDragActive ? (
              <span className="drop-area-button-text">
                Чтобы добавить файлы - отпустите клавишу мыши
              </span>
            ) : (
              <span className="drop-area-button-text">
                Перетащите сюда файлы или{' '}
                <button className="drop-area-button-text bold">
                  выберете в проводнике
                </button>
              </span>
            )}
            <input
              className="drop-area-upload-input"
              type="file"
              onChange={onUploadInputChange}
              {...getInputProps({
                accept:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .csv',
              })}
            />
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default Droparea;
