import React from 'react';
import './style.scss';
import Button from '../components/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const NetworkNotFound: React.FC<RouteComponentProps> = props => {
  const redirectToHomePage = () => {
    props.history.push('/');
  };
  return (
    <div className="network-not-found-container">
      <div className="network-not-found-content">
        <div className="network-not-found-img" />
        <div className="network-not-found-general-text">
          Упс! Запрашиваемая сеть не найдена.
        </div>
        <div className="network-not-found-detailed-text">
          Данная сеть либо не существовала, либо находится по другой ссылке.
        </div>
        <div className="network-not-found-button-wrapper">
          <Button
            className="network-not-found-button"
            onClick={redirectToHomePage}
          >
            На главную
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NetworkNotFound);
