import { createSlice } from '@reduxjs/toolkit';
import { StoreType } from 'store';

import reducers from './authReducers';
import { AuthSliceStateType } from './types';

const initialState: AuthSliceStateType = { auth: false };

export const authSlice = createSlice({
  reducers,
  initialState,
  name: 'authentication',
});

export const { setAuth } = authSlice.actions;

export const getAuth = (store: StoreType) =>
  store.authentication.auth;

export default authSlice.reducer;
