import React from 'react';
import { Layer, Feature } from 'react-mapbox-gl';

import { ILayer } from './types';
import { selectTaskSolution } from 'Network/networkSlice';
import { StoreType } from 'store';
import { connect } from 'react-redux';

class WellfieldsLayer extends React.Component<ILayer> {
  onMouseEnter = (e: any) => {
    const featureId = e.features[0].id;
    const map = e.target;
    const sourceId = e.features[0].layer.id;
    const onHoverSource = map.getSource('hoverWells');
    const source = map.getSource(sourceId);
    const initData = source._data;

    const data = {
      type: 'FeatureCollection',
      features: initData?.features.filter(
        ({ properties: { id } }) => id !== featureId,
      ),
    };

    const onHoverData = {
      type: 'FeatureCollection',
      features: initData?.features.filter(
        ({ properties: { id } }) => id === featureId,
      ),
    };

    onHoverSource.setData(onHoverData);

    source.setData(data);

    map.getCanvas().style.cursor = 'pointer';

    map.on('click', 'hoverWells', this.onClickHandler);

    map.on('mouseleave', 'hoverWells', () => {
      source.setData(initData);
      onHoverSource.setData({
        type: 'FeatureCollection',
        features: [],
      });
      map.getCanvas().style.cursor = 'grab';
      map.off('click', 'hoverWells', this.onClickHandler);
    });
  };

  onClickHandler = (e: any) => {
    const feature = e.features[0];
    const { coordinates: popupCoords } = feature.geometry;
    const { source } = feature.properties;
    const { state, ...props } = JSON.parse(source);

    this.props.handlePopup({
      popupCoords,
      showPopup: true,
      popup: 'wellfield',
      activeValue: {
        ...state,
        ...props,
      },
    });
  };

  render() {
    const { taskSolution } = this.props;

    if (!taskSolution) return null;

    const sources = taskSolution.nodes?.filter(
      ({ node_type }) => node_type === 'SOURCE',
    );

    return (
      <>
        <Layer
          type="symbol"
          id="hoverWells"
          geoJSONSourceOptions={{ generateId: true }}
          layout={{ 'icon-image': 'well-hover' }}
        />
        <Layer
          id="wells"
          type="symbol"
          layout={{ 'icon-image': 'well' }}
          geoJSONSourceOptions={{ generateId: true }}
        >
          {sources?.map((source, index) => {
            const properties = {
              source,
              type: 'wellfield',
            };
            return (
              <Feature
                key={index}
                onMouseEnter={this.onMouseEnter}
                coordinates={source.coords}
                properties={properties}
              />
            );
          })}
        </Layer>
      </>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
});

export default connect(mapStateToProps)(WellfieldsLayer);
