import * as React from 'react';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import Dashboard from '../Dashboard';
import classnames from 'classnames';

import { IComparationState, IComparationProps } from './types';

class Comparation extends React.Component<IComparationProps> {
  state: IComparationState = {
    show: false,
    title: 'Сравнение с Техрежимом',
    subTitle: 'Средняя/максимальная относительная погрешность',
  };

  componentDidMount() {
    this.handleProps();
  }

  componentDidUpdate(prevPops: IComparationProps) {
    if (prevPops.optimized !== this.props.optimized) {
      this.handleProps();
    }
  }

  handleProps = () => {
    const { optimized } = this.props;

    const title = optimized
      ? 'Сравнение с фактическим режимом'
      : 'Сравнение с Техрежимом';

    const subTitle = optimized
      ? 'Среднее/максимальное изменение'
      : 'Средняя/максимальная относительная погрешность';

    this.setState({
      title,
      subTitle,
    });
  };

  onClickHandler = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { show, title, subTitle } = this.state;
    const { dashboardItems } = this.props;
    return (
      <div className={classnames('comparation', { active: show })}>
        <div
          onClick={this.onClickHandler}
          role="button"
          className="comparation__top-row"
        >
          <p className="comparation-title">{title}</p>
          <Arrow className="comparation-arrow" />
        </div>
        {show && (
          <>
            <p className="comparation-text">{subTitle}</p>
            <div className="comparation__main-container">
              <Dashboard dashboardItems={dashboardItems} />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Comparation;
