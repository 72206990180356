import * as React from 'react';
import { connect } from 'react-redux';
import { selectTaskSolution } from '../networkSlice';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Table from './Table';
import classnames from 'classnames';
import { wellfieldsTableHead, nodesTableHead, pipesTableHead } from '../utils';
import { TablesPropsType, TablesStateType } from './types';
import { StoreType } from 'store';

class Tables extends React.Component<TablesPropsType> {
  ref = React.createRef<HTMLDivElement>();
  state: TablesStateType = {
    top: 0,
  };

  componentDidUpdate(_, prevState: TablesStateType) {
    if (prevState.top > this.state.top) {
      const parentNode = this.ref.current?.parentNode?.parentNode as any;
      if (parentNode) {
        parentNode.scrollTop = this.state.top;
      }
    }
  }

  onSelect = () => {
    const parentNode = this.ref.current?.parentNode?.parentNode as any;
    const scrollTop = parentNode.scrollTop;
    this.setState({ top: scrollTop });
  };

  render() {
    const { taskSolution } = this.props;

    const nodesData = taskSolution?.nodes?.map(({ state, ...props }) => ({
      ...props,
      ...state,
    }));

    return (
      <div ref={this.ref} className={classnames('network-tables')}>
        <Tabs onSelect={this.onSelect}>
          <TabList>
            <Tab>Скважины</Tab>
            <Tab>Узлы</Tab>
            <Tab>Трубы</Tab>
          </TabList>
          <div className="network-tables__container">
            <TabPanel>
              <Table
                type="wellfield"
                tableHead={wellfieldsTableHead}
                data={taskSolution?.wells}
              />
            </TabPanel>
            <TabPanel>
              <Table type="node" tableHead={nodesTableHead} data={nodesData} />
            </TabPanel>
            <TabPanel>
              <Table
                type="pipe"
                tableHead={pipesTableHead}
                data={taskSolution?.pipelines}
              />
            </TabPanel>
          </div>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreType) => ({
  taskSolution: selectTaskSolution(state),
});

export default connect(mapStateToProps)(Tables);
