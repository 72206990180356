import * as React from 'react';

import { ReactComponent as Icon } from 'assets/images/moveToSelection.svg';

interface IProps {
  onClick: () => void;
}

const SetCenterButton: React.FC<IProps> = ({ onClick }) => {
  return (
    <button
      className="set-center-button custom-zoom-control__button"
      onClick={onClick}
      type="button"
    >
      <Icon />
    </button>
  );
};

export default SetCenterButton;
