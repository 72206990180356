import * as React from 'react';
import { connect } from 'react-redux';
import { Popup } from 'react-mapbox-gl';

import PopupComponent from './PopupComponent';
import { selectPopupData } from 'Map/mapSlice';
import { StoreType } from 'store';

interface IProps {
  popupData: ReturnType<typeof selectPopupData>;
}

class PopupContainer extends React.Component<IProps> {
  render() {
    const { popupData } = this.props;
    if (!popupData) return null;
    const { coords, popupType } = popupData;
    return (
      <Popup style={{ zIndex: 99 }} coordinates={coords}>
        <PopupComponent type={popupType} />
      </Popup>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  popupData: selectPopupData(store),
});

export default connect(mapStateToProps)(PopupContainer);
