import * as React from 'react';
import { connect } from 'react-redux';
import { Layer, Feature } from 'react-mapbox-gl';
import { selectTaskSolution } from '../Network/networkSlice';
import { ILayer } from './types';
import { StoreType } from 'store';

class NodesLayer extends React.Component<ILayer> {
  onMouseEnter = (e: any) => {
    const featureId = e.features[0].id;
    const map = e.target;
    const sourceId = e.features[0].layer.id;
    const onHoverSource = map.getSource('hoverNodes');
    const source = map.getSource(sourceId);
    const initData = source._data;

    const data = {
      type: 'FeatureCollection',
      features: initData?.features.filter(
        ({ properties: { id } }) => id !== featureId,
      ),
    };

    const onHoverData = {
      type: 'FeatureCollection',
      features: initData?.features.filter(
        ({ properties: { id } }) => id === featureId,
      ),
    };

    onHoverSource.setData(onHoverData);

    source.setData(data);

    map.getCanvas().style.cursor = 'pointer';

    map.on('click', 'hoverNodes', this.onClickHandler);

    map.on('mouseleave', 'hoverNodes', () => {
      source.setData(initData);
      onHoverSource.setData({
        type: 'FeatureCollection',
        features: [],
      });
      map.getCanvas().style.cursor = 'grab';
      map.off('click', 'hoverNodes', this.onClickHandler);
    });
  };

  onClickHandler = (e: any) => {
    const feature = e.features[0];
    const { coordinates: popupCoords } = feature.geometry;
    const { node } = feature.properties;
    const { state, ...props } = JSON.parse(node);

    this.props.handlePopup({
      popupCoords,
      showPopup: true,
      popup: 'node',
      activeValue: { ...state, ...props },
    });
  };

  render() {
    const { taskSolution } = this.props;

    if (!taskSolution) return null;

    const sources = taskSolution.nodes?.filter(({ node_type }) =>
      ['JUNCTION', 'SINK'].includes(node_type),
    );

    if (!sources?.length) return null;

    return (
      <>
        <Layer
          type="symbol"
          id="hoverNodes"
          geoJSONSourceOptions={{ generateId: true }}
          layout={{ 'icon-image': 'node-hover' }}
        />
        <Layer
          id="nodes"
          type="symbol"
          layout={{ 'icon-image': 'node' }}
          geoJSONSourceOptions={{ generateId: true }}
        >
          {sources?.map((source, index) => {
            const properties = {
              type: 'node',
              node: source,
            };
            return (
              <Feature
                key={index}
                onClick={this.onClickHandler}
                onMouseEnter={this.onMouseEnter}
                coordinates={source.coords}
                properties={properties}
              />
            );
          })}
        </Layer>
      </>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
});

export default connect(mapStateToProps)(NodesLayer);
