import * as React from 'react';
import { connect } from 'react-redux';
import TableRow from './TableRow';
import classnames from 'classnames';
import { ITableProps, ITableState } from './types';
import { selectPopupData, setPopupData } from 'Map/mapSlice';
import { StoreType } from 'store';
import { setActive } from './utils';
import TableHead from './TableHead';

class Table extends React.Component<ITableProps> {
  state: ITableState = {};

  onClickHandler = (el: any) => {
    const { type } = this.props;
    this.props.updatePopupData({
      ...el,
      popupType: type,
      name: el?.well_pad ? el?.well_pad : el.name,
      uid: el?.node_uid ? el?.node_uid : el.uid,
      coords: el?.midCoords ? el?.midCoords : el.coords,
    });
  };

  onMouseLeaveHandler = () => {
    this.setState({ activeHoverValue: false });
  };

  onMouseEnterHandler = (el: any) => {
    this.setState({ activeHoverValue: el });
  };

  render() {
    const {
      tableHead = {},
      data = [{}, {}, {}, {}, {}, {}],
      type,
      popupData,
    } = this.props;
    const { activeHoverValue } = this.state;
    const headKeys = Object.keys(tableHead);

    return (
      <table className={classnames('table', type)}>
        <TableHead headKeys={headKeys} tableHead={tableHead} />
        <tbody>
          {data.map((el: any, index: number) => {
            const active = setActive(type, el, popupData);
            const activeHover = setActive(type, el, activeHoverValue);

            return (
              <TableRow
                onMouseEnterHandler={this.onMouseEnterHandler}
                onMouseLeaveHandler={this.onMouseLeaveHandler}
                onClickHandler={this.onClickHandler}
                headKeys={headKeys}
                el={el}
                key={index}
                active={active}
                activeHover={activeHover}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state: StoreType) => ({
  popupData: selectPopupData(state),
});

const mapDispatchToProps = {
  updatePopupData: setPopupData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
