import * as React from 'react';
import classnames from 'classnames';
import './style.scss';
import { IButton } from './types';

const Button: React.FC<IButton> = ({
  className,
  children,
  mode = 'primary',
  onClick,
}) => (
  <button className={classnames('button', [mode, className])} onClick={onClick}>
    {children}
  </button>
);

export default Button;
