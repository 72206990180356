import * as React from 'react';
import { connect } from 'react-redux';

import { Layer, Feature } from 'react-mapbox-gl';
import handleCursor from './utils/handleCursor';

import { ILayer } from './types';
import { StoreType } from 'store';
import { selectTaskSolution } from 'Network/networkSlice';

const style = { 'line-color': '#0559FD', 'line-width': 2 };

const layout = { 'line-join': 'round', 'line-cap': 'round' };

class PipesLayer extends React.Component<ILayer> {
  onMouseEnter = handleCursor('pointer');

  onMouseLeave = handleCursor('grab');

  onClickHandler = (e: any) => {
    const { lat, lng } = e.lngLat;
    const { pipe } = e.features[0].properties;

    this.props.handlePopup({
      popupCoords: [lng, lat],
      showPopup: true,
      popup: 'pipe',
      activeValue: {
        ...JSON.parse(pipe),
        coords: [lng, lat],
      },
    });
  };

  render() {
    const { taskSolution } = this.props;

    if (!taskSolution) return null;

    return (
      <>
        <Layer
          id="pipe"
          type="line"
          layout={layout}
          paint={style}
          geoJSONSourceOptions={{ generateId: true }}
        >
          {taskSolution?.pipelines?.map((pipe, index) => {
            const properties = {
              pipe,
              type: 'pipe',
            };
            return (
              <Feature
                key={index}
                onMouseLeave={this.onMouseLeave}
                onMouseEnter={this.onMouseEnter}
                onClick={this.onClickHandler}
                coordinates={pipe.coords}
                properties={properties}
              />
            );
          })}
        </Layer>
      </>
    );
  }
}

const mapStateToProps = (store: StoreType) => ({
  taskSolution: selectTaskSolution(store),
});

export default connect(mapStateToProps)(PipesLayer);
