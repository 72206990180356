import React from 'react';
import classnames from 'classnames';
import './wellfieldSettings.scss';
import { UploadedFileError } from '../types';

interface IUploadedFileProps {
  fileName: string;
  index: number;
  error: UploadedFileError;
  onFileDelete?(index: number): void;
  panRef: React.RefObject<HTMLDivElement>;
  scrolled: boolean;
  added: boolean;
}

interface IUploadedFileState {
  top: boolean;
}

class UploadedFile extends React.Component<IUploadedFileProps> {
  state: IUploadedFileState = {
    top: false,
  };

  onDelete = () => {
    if (typeof this.props.onFileDelete === 'function') this.props.onFileDelete(this.props.index);
  };

  render() {
    const { error, fileName } = this.props;
    return (
      <>
        <div
          className={classnames(
            'mode-table-file-panel relative',
            error.error && 'error',
          )}
        >
          <div className="file-panel-content">
            <div className="file-panel-excel-icon" />
            <span className="file-panel-content-text">{fileName}</span>
            <button
              className="file-panel-delete-icon"
              onClick={this.onDelete}
            />
          </div>
        </div>
      </>
    );
  }
}

export default UploadedFile;
