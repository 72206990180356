import * as React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as SettingsLogo } from '../../assets/images/settings.svg';
import { ITopRowProps } from './types';

class TopRowContainer extends React.Component<ITopRowProps> {
  render() {
    const { showLink, children } = this.props;
    return (
      <div className="left-col__top-row-container">
        <div className="left-col__top-row-container-shadow" />
        <div className="left-col__top-row">
          <Logo />
          {showLink && (
            <Link className="link left-col__link" to="/settings">
              <SettingsLogo />
              <span className="left-col__link-text">
                Изменить входные данные
              </span>
            </Link>
          )}
        </div>
        <div className="left-col__top-sub-row">{children}</div>
      </div>
    );
  }
}

export default TopRowContainer;
