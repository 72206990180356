import { createSlice } from '@reduxjs/toolkit';
import { StoreType } from 'store';

import reducers from './networkReducers';
import { NetworkSliceStateType } from './types';

const initialState: NetworkSliceStateType = {};

export const networkSlice = createSlice({
  reducers,
  initialState,
  name: 'network',
});

export const { setTaskSolution } = networkSlice.actions;

export const selectTaskSolution = (store: StoreType) =>
  store.network.taskSolution;

export default networkSlice.reducer;
