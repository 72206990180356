import * as React from 'react';

import { ReactComponent as ZoomIn } from 'assets/images/zoomIn.svg';
import { ReactComponent as ZoomOut } from 'assets/images/zoomOut.svg';

import classnames from 'classnames';
import { zoomButtonType } from './types';

interface IProps {
  zoomType: zoomButtonType;
  zoomHandler: (t: zoomButtonType) => void;
}

const CustomZoomButton: React.FC<IProps> = ({ zoomHandler, zoomType }) => {
  const onClickHandler = () => zoomHandler(zoomType);
  return (
    <button
      onClick={onClickHandler}
      className={classnames('custom-zoom-control__button', zoomType)}
      type="button"
    >
      {zoomType === 'zoom-in' ? <ZoomIn /> : <ZoomOut />}
    </button>
  );
};

export default CustomZoomButton;
