import { StylesConfig } from 'react-select';

const handleStyles: StylesConfig = {
  control: (provided, state) => {
    const borderColor = state.selectProps.error ? '#f0115e' : '#DFE0E4';
    return {
      ...provided,
      borderColor,
      borderRadius: 2,
      borderWidth: 1,
      padding: '9px 12px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: state.selectProps.error ? '#f0115e' :'#BFC0C9',
      },
    };
  },
  menuList: (provided, state) => {
    return {
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 2,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
    };
  },
  option: (provided, state: { isSelected: boolean }) => {
    const color = state.isSelected ? '#046AD2' : '#0F2E4A';
    return {
      ...provided,
      color,
      backgroundColor: '#fff',
      transition: 'all 0.25s ease',
      fontSize: 14,
      lineHeight: '20px',
      cursor: 'pointer',
      padding: '10px 12px',
      '&:hover': {
        backgroundColor: 'rgba(4, 106, 210, 0.08)',
      },
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 1,
      paddingRight: 1,
      borderRadius: 2,
      backgroundColor: '#fff',
      boxShadow: 'none',
    };
  },
  indicatorsContainer: (provided, state) => {
    return {
      ...provided,
      fontSize: 0,
    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      padding: 0,
    };
  },
  input: (provided, state) => {
    return {
      ...provided,
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      fontSize: 14,
      lineHeight: '20px',
      color: '#0F2E4A',
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      fontSize: 14,
      lineHeight: '20px',
      marginLeft: 0,
    };
  },
  singleValue: (provided, state) => {
    const color = state.selectProps.error ? '#f0115e' : '#0F2E4A';
    return {
      ...provided,
      color,
      marginLeft: 0,
      fontSize: 14,
      lineHeight: '20px',
    };
  },
};

export default handleStyles;
