import * as React from 'react';
import classnames from 'classnames';
import { formatter } from '../../utils';
import { Skeleton } from '@material-ui/lab';
import { IDashboardProps } from './types';

const handleValue = (value: string | number | undefined) => {
  if (value !== undefined && value !== '') {
    if (typeof value === 'string') {
      return value;
    }
    return formatter.format(value);
  }
  return (
    <Skeleton
      variant="rect"
      classes={{
        root: 'skeleton-root dashboard-skeleton',
      }}
    />
  );
};

const Dashboard: React.FC<IDashboardProps> = ({
  mode = 'small',
  dashboardItems = [],
}) => {
  return (
    <div className={classnames('network-dashboard', mode)}>
      {dashboardItems.map((el, key) => {
        return (
          <div
            key={key}
            className={classnames('network-dashboard__item', mode)}
          >
            <p className="network-dashboard__item-dem">{el.dem}</p>
            <div className="network-dashboard__bottom">
              <p className="network-dashboard__item-value">
                {handleValue(el.value)}
              </p>
              {!!el.diff && (
                <p
                  className={classnames('network-dashboard__item-diff', [
                    el.diff > 0 ? 'green' : 'red',
                  ])}
                >
                  {el.diff > 0 ? '+' : '-'}
                  {handleValue(el.diff)}
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Dashboard;
