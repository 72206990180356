import * as React from 'react';

import PopupWell from './PopupWell';
import PopupNode from './PopupNode';
import PopupPipe from './PopupPipe';

import { popupType } from './types';

interface IProps {
  type: popupType;
}

const PopupComponent: React.FC<IProps> = ({ type }) => {
  if (type === 'node') return <PopupNode />;
  if (type === 'pipe') return <PopupPipe />;
  return <PopupWell />;
};

export default PopupComponent;
