import { combineReducers, configureStore } from '@reduxjs/toolkit';
import networkSlice from './Network/networkSlice';
import authSlice from './Login/authSlice';
import mapSlice from './Map/mapSlice';

const rootReducer = combineReducers({
  network: networkSlice,
  authentication: authSlice,
  map: mapSlice,
});

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type StoreType = ReturnType<typeof rootReducer>;
