import * as React from 'react';

const LoadingPanel: React.FC = () => (
  <div className="loading-panel-container">
    <div className="loading-panel-content">
      <span className="loading-panel-content-text">
        <div className="loading-panel-img" />
        Идет загрузка сети...
      </span>
    </div>
  </div>
);

export default LoadingPanel;
