import React from 'react';
import './WellfieldSettings/wellfieldSettings.scss';
import WellfieldSettings from './WellfieldSettings/WellfieldSettings';
import GatheringCenterSettings from './GatheringCentersSettings/GatheringCenterSettings';
import { ValueType } from 'react-select';
import {
  OptionType,
  CenterType,
  ParameterType,
  UploadedFileType, DropzoneError, WellfieldError, UploadedFileError,
} from './types';
import TaskSettings from './TaskSettings/TaskSettings';

interface ISettingsFrameProps {
  index: number;
  wellfieldValue?: ValueType<OptionType>;
  setWellfieldValue(value: ValueType<OptionType>): void;
  wellfieldValueError?: WellfieldError;
  setWellfieldValueError?(value: WellfieldError): void;
  uploadedFiles: UploadedFileType[];
  setUploadedFiles(files: UploadedFileType[]): void;
  dropzoneError: DropzoneError;
  setDropzoneError(error: DropzoneError): void;
  centerToAdd: CenterType;
  setCenterToAdd(value: CenterType);
  centerValue: ValueType<OptionType>;
  setCenterValue(value: ValueType<OptionType>): void;
  centers: CenterType[];
  setCenters(centers: CenterType[]): void;
  initOptions: OptionType[];
  centersOptions: ValueType<OptionType>[];
  setCentersOptions(options: ValueType<OptionType>[]);
  parameters: ParameterType[];
  setParameters(value: ParameterType[]);
  taskCheckbox1: boolean;
  setTaskCheckbox1(value: boolean): void;
  taskCheckbox2: boolean;
  setTaskCheckbox2(value: boolean): void;
  forceRerender?: boolean;
  setForceRerender?(value: boolean);
  wellfieldOptions: OptionType[];
}

const SettingsFrame: React.FC<ISettingsFrameProps> = ({
  index,
  wellfieldValue,
  setWellfieldValue,
  wellfieldValueError,
  setWellfieldValueError,
  uploadedFiles,
  setUploadedFiles,
  dropzoneError,
  setDropzoneError,
  centerToAdd,
  setCenterToAdd,
  centerValue,
  setCenterValue,
  centers,
  setCenters,
  initOptions,
  centersOptions,
  setCentersOptions,
  parameters,
  setParameters,
  taskCheckbox1,
  setTaskCheckbox1,
  taskCheckbox2,
  setTaskCheckbox2,
  wellfieldOptions,
}) => {
  const filesErrors: UploadedFileError[] = [];
  for (const file of uploadedFiles) {
    if (file.error.error) filesErrors.push(file.error);
  }
  switch (index) {
    case 0:
      return (
        <WellfieldSettings
          wellfieldValue={wellfieldValue}
          setWellfieldValue={setWellfieldValue}
          wellfieldValueError={wellfieldValueError}
          setWellfieldValueError={setWellfieldValueError}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          dropzoneError={dropzoneError}
          setDropzoneError={setDropzoneError}
          wellfieldOptions={wellfieldOptions}
          uploadedFilesErrors={filesErrors}
        />
      );
    case 1:
      return (
        <GatheringCenterSettings
          centerToAdd={centerToAdd}
          setCenterToAdd={setCenterToAdd}
          centerValue={centerValue}
          setCenterValue={setCenterValue}
          centers={centers}
          setCenters={setCenters}
          initOptions={initOptions}
          centersOptions={centersOptions}
          setCentersOptions={setCentersOptions}
        />
      );
    case 2:
      return (
        <TaskSettings
          parameters={parameters}
          setParameters={setParameters}
          taskCheckbox1={taskCheckbox1}
          setTaskCheckbox1={setTaskCheckbox1}
          taskCheckbox2={taskCheckbox2}
          setTaskCheckbox2={setTaskCheckbox2}
        />
      );
    default:
      return <></>;
  }
};

export default SettingsFrame;
