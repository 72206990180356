import { createSlice } from '@reduxjs/toolkit';
import { StoreType } from 'store';

import reducers from './mapReducers';
import { MapSliceStateType } from './types';

const initialState: MapSliceStateType = {
  popup: null,
};

export const networkSlice = createSlice({
  reducers,
  initialState,
  name: 'map',
});

export const { setPopupData } = networkSlice.actions;

export const selectPopupData = (store: StoreType) => store.map.popup;

export default networkSlice.reducer;
