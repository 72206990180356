import { api } from 'api';
import { saveAs } from 'file-saver';

export const getSolution = ({ taskId }) =>
  api.get('https://api.pipeline-network-optimization.numdes.com/api/get_solution', {
    params: {
      task_id: taskId,
    },
  });

export const calculateOptimizedTask = ({ id, task }) =>
  api.post('https://api.pipeline-network-optimization.numdes.com/api/calc_opt_task', task, {
    params: {
      ident_task_id: id,
    },
  });

export const downloadResult = (taskId: string) =>
  api({
    url: `https://api.pipeline-network-optimization.numdes.com/api/download_result?task_id=${taskId}`,
    method: 'GET',
    responseType: 'blob',
  }).then(({ data }) => saveAs(data));
