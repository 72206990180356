import { api } from 'api';

export const getWellfields = () => {
  return api.get('https://api.pipeline-network-optimization.numdes.com/api/get_wellfields', {});
};

export const getGatheringCenters = ({ wellfieldUid }) => {
  return api.get('https://api.pipeline-network-optimization.numdes.com/api/get_gathering_centers', {
    params: {
      wellfield_uid: wellfieldUid,
    },
  });
};

export const uploadFile = (formData) => {
  return api.post('https://api.pipeline-network-optimization.numdes.com/api/upload_file', formData);
};

export const getOverviewInfo = (techmodeFileUid: string, wellsTablesFileUid: string) => {
  return api.get('https://api.pipeline-network-optimization.numdes.com/api/get_input_files_differences', {
    params: {
      techmode_file_uid: techmodeFileUid,
      wells_tables_file_uid:  wellsTablesFileUid,
    },
  });
};

export const restoreNetwork = ({
  uploadedFiles,
  centers,
  parameters,
  wellfieldUid,
  useAspo,
  useInclination,
}) => {
  const gcPressures = {};
  for (const center of centers) {
    gcPressures[center.uid] = parseFloat(center.pressure.replace(/,/, '.'));
  }
  const filesArr: { uid: string; wellfield_uid: string; name: string }[] = [];
  for (const file of uploadedFiles) {
    filesArr.push({
      uid: file.uid,
      wellfield_uid: wellfieldUid,
      name: file.name,
    });
  }
  return api.post('https://api.pipeline-network-optimization.numdes.com/api/calc_ident_task', {
    wellfield_uid: wellfieldUid,
    gc_pressures: gcPressures,
    files: filesArr,
    phys_chem: {
      roughness_mm: parseFloat(
        parameters[0].value.replace(/\s/g, '').replace(/,/, '.'),
      ),
      oil_density_kg_m3: parseFloat(
        parameters[1].value.replace(/\s/g, '').replace(/,/, '.'),
      ),
      water_density_kg_m3: parseFloat(
        parameters[2].value.replace(/\s/g, '').replace(/,/, '.'),
      ),
      gas_rel_density: parseFloat(
        parameters[3].value.replace(/\s/g, '').replace(/,/, '.'),
      ),
      oil_viscosity_20_cp: parseFloat(
        parameters[4].value.replace(/\s/g, '').replace(/,/, '.'),
      ),
      oil_viscosity_50_cp: parseFloat(
        parameters[5].value.replace(/\s/g, '').replace(/,/, '.'),
      ),
      fluid_temperature: parseFloat(
        parameters[6].value.replace(/\s/g, '').replace(/,/, '.'),
      ),
    },
    init_node_states: [],
    use_aspo: useAspo,
    use_inclination: useInclination,
  });
};
