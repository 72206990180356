import * as React from 'react';
import { connect } from 'react-redux';
import { selectPopupData, setPopupData } from 'Map/mapSlice';
import { selectTaskSolution } from 'Network/networkSlice';
import { StoreType } from 'store';
import { PopupWellPropsType } from './types';
import TableRow from './TableRow';

const PopupWell: React.FC<PopupWellPropsType> = ({
  popupData,
  updatePopupData,
  taskSolution,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    ref.current?.focus();
  }, []);

  const hidePopup = () => updatePopupData(null);

  const { name, uid } = popupData;
  const { wellsById = {} } = taskSolution;
  const wells = wellsById[uid] || [];

  return (
    <div tabIndex={-1} onBlur={hidePopup} ref={ref}>
      <div className="popup-top-row">
        <div className="popup-title">{name}</div>
      </div>
      <table>
        <tbody>
          {wells.map((el, key) => {
            const {
              frequency_hz: frequency,
              fluid_flow_rate_ton_day: fluidFlow,
              gas_flow_rate_m3_day: gasFlow,
              oil_flow_rate_ton_day: oilFlow,
              pressure_atm: pressure,
            } = el;

            return (
              <TableRow
                key={key}
                title={`Скважина ${el.name}`}
                data={{
                  pressure,
                  frequency,
                  fluidFlow,
                  oilFlow,
                  gasFlow,
                }}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store: StoreType) => ({
  popupData: selectPopupData(store),
  taskSolution: selectTaskSolution(store),
});

const mapDispatchToProps = { updatePopupData: setPopupData };

export default connect(mapStateToProps, mapDispatchToProps)(PopupWell);
