import React from 'react';

interface ITaskSettingsCheckboxProps {
  checkedValue: boolean;
  setCheckedValue(value: boolean): void;
  label: string;
  id: string;
}

const TaskSettingsCheckbox: React.FC<ITaskSettingsCheckboxProps> = ({
  checkedValue,
  setCheckedValue,
  label,
  id,
}) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof setCheckedValue === 'function') setCheckedValue(e.target.checked);
  };
  return (
    <div className="task-settings-row checkbox-row ">
      <input
        id={id}
        type="checkbox"
        aria-checked={false}
        className="task-settings-checkbox-input"
        checked={checkedValue}
        onChange={onChangeValue}
      />
      <label
        htmlFor={id}
        className="task-settings-checkbox-label"
      >
        {label}
      </label>
    </div>
  );
};

export default TaskSettingsCheckbox;
