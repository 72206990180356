import React from 'react';

interface IErrorRowProps {
  errorMessage: string;
  errorDescription: string;
}

const ErrorRow: React.FC<IErrorRowProps> = props => {
  return (
    <div className="wellfield-settings-error-row relative">
      <div className="error-row-image" />
      <span className="error-row-text error-text">{props.errorMessage}</span>
      <span className="error-row-text">{props.errorDescription}</span>
    </div>
  );
};

export default ErrorRow;
