import React, { createRef } from 'react';
import Input from '../../components/Input';
import './gatheringCenterSettings.scss';
import { CenterType } from '../types';
import classnames from 'classnames';
import { formatterForInt } from '../../utils';
import stringFormatter from '../../utils/stringFormatter';

interface ICenterRowProps {
  centerIx: number;
  item: CenterType;
  centers: CenterType[];
  setCenters(value: CenterType[]): void;
  onDeleteCenter(index: number, center: string): void;
  panRef: React.RefObject<HTMLDivElement>;
  scrolled: boolean;
  added: boolean;
}

interface ICenterRowState {
  cursorPosition: number | null;
  top: boolean;
}

class GatheringCenterRow extends React.Component<ICenterRowProps> {
  tooltipRef = createRef<HTMLDivElement>();
  state: ICenterRowState = {
    cursorPosition: null,
    top: false,
  };

  componentDidMount(): void {
    this.checkTooltip();
  }

  componentDidUpdate(prevProps: ICenterRowProps, prevState: ICenterRowState): void {
    if (
      prevProps.scrolled !== this.props.scrolled ||
      prevProps.added !== this.props.added
    ) {
      this.setState({ top: false }, this.checkTooltip);
    }
  }

  checkTooltip = () => {
    if (this.tooltipRef.current && this.props.panRef.current) {
      const tooltipRect = this.tooltipRef.current.getBoundingClientRect();
      const panelRect = this.props.panRef.current.getBoundingClientRect();
      const styles = getComputedStyle(this.props.panRef.current);
      const panelMaxBottom = panelRect.top + parseInt(styles.maxHeight, 10);
      if (
        tooltipRect.bottom > panelMaxBottom ||
        tooltipRect.bottom >
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.setState({ top: true });
      }
    }
  };

  onDeleteHandler = () => {
    if (typeof this.props.onDeleteCenter === 'function') this.props.onDeleteCenter(this.props.centerIx, this.props.item.name);
  };
  onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const spaces =
      stringFormatter(e.target.value).length - e.target.value.length;
    if (spaces > 0) {
      if (e.target.selectionStart) {
        this.setCursorPosition(e.target.selectionStart + spaces);
      }
    } else this.setCursorPosition(e.target.selectionStart);
    if (this.props.centerIx > -1) {
      const centersCopy = [...this.props.centers];
      centersCopy[this.props.centerIx].pressure = e.target.value;
      centersCopy[this.props.centerIx].pressureError = false;
      this.props.setCenters(centersCopy);
    }
  };
  setCursorPosition = (position) => {
    this.setState({ cursorPosition: position });
  };
  onBlurHandler = () => {
    if (this.props.centerIx > -1) {
      const centersCopy = [...this.props.centers];
      const newItem = { ...centersCopy[this.props.centerIx] };
      if (this.props.item.pressure) {
        const commaToDotValue = this.props.item.pressure.replace(',', '.');
        const valueWithoutSpaces = commaToDotValue.replace(/\s/g, '');
        const newValue = parseFloat(valueWithoutSpaces);
        if (newValue) {
          newItem.pressure = formatterForInt.format(newValue).toString();
          newItem.pressureError = false;
        } else {
          newItem.pressure = this.props.item.pressure;
          newItem.pressureError = true;
          newItem.pressureErrorType = 'notNumber';
        }
      } else {
        newItem.pressure = this.props.item.pressure;
        newItem.pressureError = true;
        newItem.pressureErrorType = 'emptyValue';
      }
      centersCopy[this.props.centerIx] = newItem;
      this.props.setCenters(centersCopy);
    }
  };
  render() {
    const pressureInputMode = this.props.item.pressureError ? 'error' : 'normal';
    const inputValue = this.props.item.pressure ? stringFormatter(this.props.item.pressure) : this.props.item.pressure;
    const { top, cursorPosition } = this.state;
    return (
      <div className="gathering-center-row displaying-row">
        <div className="gathering-center-column left">
          <div className="settings-dropdown-container">
            <Input value={this.props.item.name} readOnly={true} />
          </div>
        </div>
        <div className="dash-column left">
          <span className="dash-column-text">&mdash;</span>
        </div>
        <div className="pressure-column left">
          <div className="settings-input-container relative">
            <Input
              value={inputValue}
              onChange={this.onChangeHandler}
              mode={pressureInputMode}
              onBlur={this.onBlurHandler}
              cursorPosition={cursorPosition}
            />
            <div
              ref={this.tooltipRef}
              className={classnames(
                'settings-tooltip gathering-center-pressure-tooltip',
                this.props.item.pressureErrorType === 'emptyValue'
                  ? 'empty-value'
                  : 'not-number',
                this.props.item.pressureError ? 'error' : '',
                { top },
              )}
            >
              <span className="settings-tooltip-text">
                {this.props.item.pressureErrorType === 'emptyValue'
                  ? 'Укажите давление на ЦС'
                  : 'Введите числовое значение'}
              </span>
            </div>
          </div>
        </div>
        <button
          className="gathering-center-row-btn delete-btn"
          onClick={this.onDeleteHandler}
        >
          Удалить
        </button>
      </div>
    );
  }
}

export default GatheringCenterRow;
