import React from 'react';
import UserLogo from '../components/UserLogo';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import './style.scss';
import Button from '../components/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';

enum ErrorType {
  notFoundError,
  internalServerError,
}

export type ErrorPagePropsType = RouteComponentProps & {
  detailedText?: string;
  type: keyof typeof ErrorType;
};

const ErrorPage: React.FC<ErrorPagePropsType> = (props) => {
  const redirectToHomePage = () => {
    props.history.push('/');
  };
  const getMessageByType = (errorType: keyof typeof ErrorType) => {
    switch (errorType) {
      case 'notFoundError': return 'Упс! Страница не найдена.';
      case 'internalServerError': return 'Внутренняя ошибка сервера.';
      default: return '';
    }
  };
  const getClassNameByType = (errorType: keyof typeof ErrorType) => {
    switch (errorType) {
      case 'notFoundError': return 'not-found-error';
      case 'internalServerError': return 'internal-server-error';
      default: return '';
    }
  };
  const { type } = props;
  const className = getClassNameByType(type);
  return (
    <div className="error-page-container">
      <UserLogo />
      <div className="error-page-top-row">
        <Logo />
      </div>
      <div className="error-page-content">
        <div className={classnames('error-page-img', className)}/>
        <div className="error-page-general-text">
          {getMessageByType(type)}
        </div>
        { typeof props.detailedText !== undefined &&
          <div className="error-page-detailed-text">
            {props.detailedText}
          </div>
        }
        <div className="error-page-button-wrapper">
          <Button
            className="error-page-button"
            onClick={redirectToHomePage}
          >
            На главную
          </Button>
        </div>
      </div>
    </div>
  );
};
export default withRouter(ErrorPage);
