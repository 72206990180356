import { popupType } from 'Map/PopupContainer/types';

type setActiveType = (type: popupType, el: any, activeValue?: any) => boolean;

export const setActive: setActiveType = (type, el, activeValue) => {
  if (type === 'wellfield' && activeValue) {
    const nodeUid = activeValue?.uid ? activeValue?.uid : activeValue?.node_uid;
    const uid = el?.node_uid;

    if (nodeUid && uid) {
      return nodeUid === uid;
    }
  }

  if (type === 'node' && activeValue) {
    const nodeUid = activeValue?.node_uid
      ? activeValue?.node_uid
      : activeValue?.uid;
    const uid = el?.uid;

    if (nodeUid && uid) {
      return nodeUid === uid;
    }
  }

  if (type === 'pipe' && activeValue) {
    const elStartNodeUid = el?.start_node_uid;
    const elEndNodeUid = el?.end_node_uid;
    const activeValueStartNodeUid = activeValue?.start_node_uid;
    const activeValueEndNodeUid = activeValue?.end_node_uid;

    if (elStartNodeUid && activeValueStartNodeUid) {
      return (
        elStartNodeUid === activeValueStartNodeUid &&
        elEndNodeUid === activeValueEndNodeUid
      );
    }
  }

  return false;
};

export const handleTdIndex = (index: number) => {
  if (index === 0) {
    return 'table-skeleton-72';
  }
  if (index <= 3) {
    return 'table-skeleton-24';
  }
  if (index <= 6) {
    return 'table-skeleton-56';
  }
  return '';
};
